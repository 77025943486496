$white: #fff;
$black: #000;

$red: #f44336;
$blue: #2196F3;

$cyan: #00bcd4;


$color-primary: #333;
$color-secondary: #666;
$color-border: #eaeaea;
$color-brand: #4a7bf7;
$color-bg: #f8f8f8;
$color-bg-hover: #FBFCF8;
