@import "../../app/styles/base";


.root {

}

.title {
  margin-bottom: 10px;
  color: $color-brand;
  font-size: $titleFontSize;
  font-weight: bold;
}
.name {
  font-size: 22px;
  color: $color-brand;
}
.line {
  margin-bottom: 10px;
  word-break: break-all;

  span {
    color: #4a7bf7;
    margin-left: 10px;
  }
}


.chemicalStructure {
  width: 350px;
  height: auto;
  margin: 20px 0;
  max-width: 100%;
  border: 1px solid $color-border;
}
