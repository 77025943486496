$menu-transition-time: 0.3s;
$menu-width: 250px;

$page-width: 1000px;
$titleFontSize: 26px;


$breakpoints: (
    pc: $page-width,         // 栅格系统pc断点
    pad: 850px,         // 栅格系统pad断点
    h5: 768px,          // 栅格系统手机断点
) !default;

