@import "../../../app/styles/base";

.root {
  :global {
    .form-control {
      background-color: $color-bg;


      &:hover {
        background-color: $color-bg-hover;
      }
      &:focus {
        background-color: #fff;
      }
    }
  }
}


.row {
  overflow: hidden;
  margin-bottom: 20px;
}
.fieldName {
  float: left;
  width: 150px;
  font-size: 18px;
  font-weight: bold;
}
.valueContainer {
  margin-left: 150px;
}
.required {
  color: #f00;
}
.error {
  color: #f00;
}

