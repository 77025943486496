
/* base */
@import 'base';

/* vendors */
:global {
  @import '~normalize.css';
  @import '~rc-pagination/assets/index.css';
  @import '~rc-select/assets/index.css';
}
/* modules */
@import "common/page";
@import "common/util";


