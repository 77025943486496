@import "../../../app/styles/base";

.root {
  article {
    border: 2px solid $color-border;
    padding: 10px 20px 0;
    margin-bottom: 10px;
  }
  section {
    margin-bottom: 20px;
  }
  div {
    font-size: 20px;
    color: #333;
    margin-bottom: 5px;
    font-weight: bold;
  }
  p {
    font-size: 18px;
    color: $color-brand;
    margin-bottom: 5px;
  }
}

:global {


}
