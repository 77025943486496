/* Styles for HomeView */
@import "../../../../app/styles/base";

.root {

}
.searchBoxContainer {
    padding: 20px 0;
    max-width: $page-width;
    margin: 0 auto;
}
.productListContainer {
  max-width: $page-width;
  margin: 20px auto;
}
.productListTitle {
  font-size: $titleFontSize;
  text-align: left;
  margin: 20px 0 20px 20px;
  font-weight: bold;
  span {
    color: #0074d9;
  }
}
.moreProducts {
  display: block;
  margin-top: 20px;
  margin-left: 20px;
}
