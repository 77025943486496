@import "../../../app/styles/base";

.root {
  padding: 10px 0 10px 30px;
  overflow: hidden;

  :global {

    .form-control {
      background-color: $color-bg;

      @include placeholder {
        color: #bbb;
      }

      &:hover {
        background-color: $color-bg-hover;
      }
      &:focus {
        background-color: #fff;
      }
    }
  }
}
.field {
  float: left;
  max-width: 700px;
  width: 57%;
  margin-right: 20px;
}

