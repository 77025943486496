@import "../../../app/styles/base";

.brand {
  @include clearfix();
  max-width: $page-width;
  margin: 0 auto;
}

.nav {
  background-color: #0177de;
  @include clearfix();
}
.navContent {
  max-width: $page-width;
  margin: 0 auto;
}
.navList {
  overflow: hidden;
  @include mq(h5) {
    padding-left: 20px;
  }
}
.navItem {
  float: left;
  padding: 0 30px;
  border-right: 1px solid #eaeaea;
  &:first-child {
    border-left: 1px solid #eaeaea;
  }
}
.navLink {
  height: 58px;
  line-height: 58px;
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  &:focus,
  &:hover,
  &:active {
    color: #f5ff93;
    text-decoration: none;
  }
}
.navLinkActive {
  color: #f5ff93;
}
.contact {
  height: 58px;
  line-height: 58px;
  color: #fff;
  padding-left: 10px;
  float: left;
  font-size: 20px;
}
