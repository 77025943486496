
@import "../../../app/styles/base";

.root {

  a {
    color: #fff;

    &:hover,
    &:active {
      color: #fff;
      text-decoration: none;
    }
  }

  :global {

    .detailContainer {
      padding: 20px 0 ;
      background-color: #35414a;
    }
    .detail {
      max-width: $page-width;
      margin: 0 auto;
      overflow: hidden;
    }
    .address {
      float: left;
      width: 60%;
      @include mq(h5) {
        float: none;
        width: auto;
        padding: 20px;
      }
    }
    .title {
      font-size: 16px;
    }
    .line {
      color: #fff;
      margin-bottom: 5px;
    }
    .label {
      color: #9e9e9e;
    }
    .brandContainer {
      float: left;
      width: 40%;
      margin-top: 20px;
      @include mq(h5) {
        float: none;
        width: auto;
      }
    }
    .copyRight {
      height: 60px;
      line-height: 60px;
      font-size: 14px;
      text-align: center;
      background-color: #2b353d;
      color: #fff;
    }

  }
}
