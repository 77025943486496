@import "../../../app/styles/base";

.root {
}
.product {
  overflow: hidden;
  padding: 10px 10px 10px 20px;
  display: block;
  border: 4px solid transparent;

  &:hover {
    border-color: #0076df;
    text-decoration: none;
  }
  &:nth-child(2n +1) {
    background-color: $color-bg;
  }
}
.detail {
}
.line {
  max-height: 60px;
  overflow: hidden;
  display: block;
  font-size: 16px;
  color: #999;
  margin-bottom: 8px;
}
.summary {
  max-height: 50px;
  overflow: hidden;
  display: block;
  font-size: 16px;
  color: #999;
  margin-bottom: 8px;
}
.name {
  font-size: 22px;
  color: $color-brand;
  margin-right: 10px;
}
.field {
  color: #0076df;
  margin-right: 10px;
}
.fieldName {
  color: #666;
  margin-right: 10px;

}

