@import "../../../app/styles/base";
// Some best-practice CSS that's useful for most apps
// Just remove them if they're not what you want


html,
body {
  margin: 0;
  padding: 0;
  min-height: 100%;
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

ul,
ol {
    list-style: none;
    margin: 0;
    padding: 0;
}

a {
  text-decoration: none;
}

:global {

  body {
    .rc-select-selection--single {
      width: 90px;
    }
  }
}
