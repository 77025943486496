@import "../../../../app/styles/base";

.root {
  background-color: #f8f8f8;
  padding: 80px 0;
  @include mq(h5) {
    padding: 20px 0;
  }
}

.about {
  max-width: $page-width;
  margin: 0 auto;
  overflow: hidden;
}
.aboutContent {
  float: left;
  width: 60%;
  padding: 0 30px 0 0;
  p {
    text-indent: 40px;
  }
  @include mq(h5) {
    float: none;
    width: auto;
    padding: 20px;
    overflow: hidden;
  }
}
.aboutTitle {
  font-size: $titleFontSize;
  margin: 0 0 30px 0;
  font-weight: bold;
  span {
    color: #0076df;
  }
}
.aboutDetail {
  font-size: 18px;
  line-height: 2;
  margin-bottom: 30px;
  white-space: pre-line;

}
.warning {
  font-size: 18px;
  color: #000;
  font-weight: bold;
}
.aboutMore {
  float: left;
  width: 150px;
  height: 46px;
  line-height: 46px;
  font-size: 18px;
  color: #fff;
  text-align: center;
  background-color: $color-brand;
  &:hover {
    color: #fff;
    text-decoration: none;
  }
}
.aboutImgContainer {
  float: left;
  width: 40%;
  @include mq(h5) {
    float: none;
    width: auto;
    margin: 20px;
  }
}
.aboutImg {
  max-width: 100%;
  width: 400px;
  height: auto;
  border: 10px solid #cecece;
  padding: 10px;
}
