:global {
  .fr {
    float: right;
  }
  .fl {
    float: left;
  }

  .pt20 {
    padding-top: 20px;
  }
  .pb20 {
    padding-bottom: 20px;
  }

  .ml10 {
    margin-left: 10px;
  }
  .mr10 {
    margin-right: 10px;
  }
  .btn {
    cursor: pointer;
  }
  .btn-brand {
    display: inline-block;
    width: 150px;
    height: 46px;
    line-height: 46px;
    font-size: 18px;
    color: #fff;
    text-align: center;
    background-color: $color-brand;
    border: 0;
    cursor: pointer;
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
      outline: none;
      color: #fff;
    }
  }
  .btn-disabled {
    display: inline-block;
    width: 150px;
    height: 46px;
    line-height: 46px;
    font-size: 18px;
    color: #fff;
    text-align: center;
    background-color: $color-bg;
    cursor: not-allowed;
    &:hover {
      text-decoration: none;
      color: #fff;
    }
  }
}
