@import "../../app/styles/base";

.root {
  font-size: 20px;
}

.request {
  width: 100px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background-color: $color-brand;
  color: #fff;
  font-size: 16px;
  display: inline-block;
  margin-left: 10px;
  position: relative;
  top: -3px;
  &:hover {
    text-decoration: none;
    color: #fff;
  }
}
.title {
  margin-bottom: 10px;
  color: $color-brand;
  font-size: $titleFontSize;
  font-weight: bold;
}
