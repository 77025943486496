@import "../../app/styles/base";


.root {

}
.title {
  margin-bottom: 10px;
  color: $color-brand;
  font-size: $titleFontSize;
  font-weight: bold;
}
.line {
  margin-bottom: 10px;
  overflow: hidden;

  span {
    color: #4a7bf7;
    margin-left: 10px;
  }
}

.download {
  float: left;
  font-size: 14px;
  width: 148px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  margin-right: 10px;
  background-color: $color-brand;
  color: #fff;
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    color: #fff;
  }
}

.downloadDisabled {
  float: left;
  font-size: 14px;
  width: 148px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  margin-right: 10px;
  background-color: $color-bg;
  cursor: not-allowed;
  color: #333;
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    color: #333;
  }
}
