@import "../../../app/styles/base";

.homeLink {
  float: left;
  padding: 20px 0 30px 0;

  @include mq(h5) {
    float: none;
    display: block;
    overflow: hidden;
    padding-left: 10px;
  }

  &.vertical {
    float: none;
    display: block;

    .logo {
      float: none;
      display: block;
      margin: 0 0 10px 64px;
    }
    .brandName {
      font-size: 34px;
    }
    .brandDetail {
      float: none;
      display: block;
    }
    .brandIntro {
      display: none;
    }
  }
}
.logo {
  float: left;
  width: 60px;
  height: 60px;
  margin-right: 10px;
}
.brandDetail {
  float: left;
  line-height: 1;
}
.brandName {
  font-size: 30px;
  color: #15293d;
  font-size: 48px;

  span:nth-child(2) {
    color: #2199e2;
  }
}
.brandIntro {
  display: block;
  font-size: 16px;
  color: #adadad;
}
