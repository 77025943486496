@import "../../../../app/styles/base";


.root {
  width: 500px;
  margin: 0 auto;

  @include mq(h5) {
    width: 250px;
  }

  :global {
    .rc-pagination-options {
      @include mq(h5) {
        float: none;

        padding-top: 40px;
        margin-left: 0;
      }
    }
  }
}
