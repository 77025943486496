@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}
}
/** 用法如下
input {
  @include placeholder {
      font-style:italic;
      color: white;
      font-weight:100;
  }
}
*/


@mixin mq($size) {
  @media (max-width: map-get($breakpoints, $size)) {
    @content;
  }
}
